import { useMemo } from "react";

import { useAuth } from "@contexts/auth";

import {
  packageSchedulersGet,
  PackageSchedulerType,
} from "../api-services/definitions/package-instances";
import { useApiGet } from "../api-services/endpoints";

type UsePackageSchedulerType = (
  clientId: string,
  packageInstanceId?: string,
  orgId?: string,
  parentClientId?: string
) => {
  packageSchedulers?: PackageSchedulerType[];
  loading: boolean;
  mutate: () => void;
};

export const usePackageSchedulers: UsePackageSchedulerType = (
  clientId,
  packageInstanceId,
  orgId,
  parentClientId
) => {
  const { oid } = useAuth();

  const userId = orgId ?? oid;

  const {
    data: packageSchedulers,
    loading,
    mutate,
  } = useApiGet(
    packageSchedulersGet,
    {
      userId: userId!,
      clientId,
    },
    {
      ...(parentClientId && { parentClientId }),
    },
    { dedupingInterval: 300000 }
  );

  const schedulers = useMemo(
    () =>
      packageSchedulers
        ?.filter((s) =>
          packageInstanceId ? s.packageInstanceId === packageInstanceId : true
        )
        ?.filter((s) => s.groupScheduler?.type !== "curriculum"),
    [packageSchedulers, packageInstanceId]
  );

  return {
    packageSchedulers: schedulers || [],
    loading,
    mutate,
  };
};
