import { useSWRConfig } from "swr";

export type UpdatePayload = {
  id: string;
  data: any;
};

export const applyUpdateToItems = <T extends { id: string }>(
  items: T[],
  update: UpdatePayload,
  merge: boolean
) => {
  const index = items.findIndex((item) => item.id === update.id);

  if (index >= 0) {
    const currentItem = items[index];
    const newItem = applyUpdateToItem(currentItem, update, merge);

    // If newItem is null, remove the item from the array
    if (newItem === null) {
      return [...items.slice(0, index), ...items.slice(index + 1)];
    }

    return [...items.slice(0, index), newItem, ...items.slice(index + 1)];
  }

  return items;
};

export const applyUpdateToItem = <T extends { id: string }>(
  item: T,
  update: UpdatePayload,
  merge: boolean
) => {
  if (item.id === update.id) {
    // If update.data is null/undefined, we remove the item
    if (update.data === null || update.data === undefined) {
      return null;
    }
    return merge ? { ...item, ...update.data } : update.data;
  }

  return item;
};

export const applyUpdateToPageOfHits = <T extends { id: string }>(
  page: {
    hits: { document: T }[];
  },
  update: UpdatePayload,
  merge: boolean
) => {
  return {
    ...page,
    hits: page.hits.map((hit) => applyUpdateToHit(hit, update, merge)),
  };
};

export const applyUpdateToHit = <T extends { id: string }>(
  hit: { document: T },
  update: UpdatePayload,
  merge: boolean
) => {
  return {
    ...hit,
    document: applyUpdateToItem(hit.document, update, merge),
  };
};

/**
 * Hook to mutate the SWR Cache. Provides a function `updateCache()` that takes in a `cacheTransformer`
 * which will be called with each cache entry that is found by the given `keyMatcher`.
 *
 * This hook does not assume any shape of the data in cache or the shape of cache keys.
 */
export const useSWRCache = () => {
  const { mutate, cache } = useSWRConfig();

  const updateCache = async <T = any>(
    keyMatcher: (key: string) => boolean,
    cacheTransformer: (key: string, cacheData: T) => T
  ) => {
    const matchingCacheKeys = Array.from(cache.keys()).filter(keyMatcher);

    for (const key of matchingCacheKeys) {
      const cacheEntry = cache.get(key);
      const cacheData = cacheEntry?.data as T;

      if (!cacheData) continue;

      const updatedData = cacheTransformer(key, cacheData);

      if (!updatedData) continue;

      await mutate(key, updatedData, false);
    }
  };

  return {
    updateCache,
  };
};

/**
 * Dedicated hook to update cache entries based on a string key. This assumes a specific shape of
 * data for single items, lists and infinite/paginated APIs.
 */
export const useUpdateAPICache = () => {
  const { updateCache } = useSWRCache();

  const updateAPICache = async (
    queryKey: string,
    updates: UpdatePayload[],
    merge: boolean = true
  ) => {
    for (const update of updates) {
      await updateCache(
        (key) => key.includes(queryKey),
        (key, cacheData) => applyUpdate(key, cacheData, update, merge)
      );
    }
  };

  const applyUpdate = (
    key: string,
    cacheData: any,
    update: UpdatePayload,
    merge: boolean
  ) => {
    if (!cacheData) return undefined;

    const isInfinite = key?.startsWith("$inf$");

    if (isInfinite) {
      return applyUpdateToInfiniteData(cacheData, update, merge);
    } else {
      if (Array.isArray(cacheData.data)) {
        return {
          ...cacheData,
          data: applyUpdateToItems(cacheData.data, update, merge),
        };
      } else {
        return {
          ...cacheData,
          data: applyUpdateToItem(cacheData.data, update, merge),
        };
      }
    }
  };

  const applyUpdateToInfiniteData = (
    data: any,
    update: UpdatePayload,
    merge: boolean
  ) => {
    return data.map((page: any) => {
      return {
        ...page,
        data: applyUpdateToItems(page.data, update, merge),
      };
    });
  };

  return {
    updateAPICache,
  };
};
